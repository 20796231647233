import { backendConfig } from "./authConfig";

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken 
 */

export async function getUserDetails(accessToken, data) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    headers.append("Content-Type", 'application/json');
    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ token: data }),
    };
    return fetch(backendConfig.backendEndpoint + 'getUserDetail', options)
        .then(response => response.json())
        .catch(error => console.log(error));
}
export async function getAllFormRequests(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    headers.append("Content-Type", 'application/json');
    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ user_type: 'Admin' }),
    };
    return fetch(backendConfig.backendEndpoint + 'getAllRequests', options)
        .then(response => response.json())
        .catch(error => console.log(error));
}
export async function getBrandManageAllFormRequests(accessToken, data) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    headers.append("Content-Type", 'application/json');
    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ user_type: 'Brand_Manager', country_id: data.country_id, user_id: data.user_id }),
    };
    return fetch(backendConfig.backendEndpoint + 'getAllRequests', options)
        .then(response => response.json())
        .catch(error => console.log(error));
}

export async function getVendorList(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    headers.append("Content-Type", 'application/json');
    const options = {
        method: "POST",
        headers: headers
    };
    return fetch(backendConfig.backendEndpoint + 'getAllVendors', options)
        .then(response => response.json())
        .catch(error => console.log(error));
}

export async function downloadExcelReportData(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    headers.append("Content-Type", 'application/json');
    const options = {
        method: "POST",
        headers: headers
    };
    return fetch(backendConfig.backendEndpoint + 'generateRequestExcel', options)
        .then(response => response.json())
        .catch(error => console.log(error));
}

export async function getCMOAllFormRequets(accessToken, data) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    headers.append("Content-Type", 'application/json');
    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ user_type: 'CMO', country_id: data.country_id }),
    };
    return fetch(backendConfig.backendEndpoint + 'getAllRequests', options)
        .then(response => response.json())
        .catch(error => console.log(error));
}
export async function getSingleFormRequest(accessToken, data) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    headers.append("Content-Type", 'application/json');
    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
    };
    return fetch(backendConfig.backendEndpoint + 'getSingleRequest', options)
        .then(response => response.json())
        .catch(error => console.log(error));
}

export async function cancelRequest(accessToken, data) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    headers.append("Content-Type", 'application/json');
    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
    };
    return fetch(backendConfig.backendEndpoint + 'cancelRequest', options)
        .then(response => response.json())
        .catch(error => console.log(error));
}

export async function getAllCountriesData(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    headers.append("Content-Type", 'application/json');
    const options = {
        method: "POST",
        headers: headers,
    };
    return fetch(backendConfig.backendEndpoint + 'getAllCountries', options)
        .then(response => response.json())
        .catch(error => console.log(error))
}
export async function getAllStrategicInitiativeData(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    headers.append("Content-Type", 'application/json');
    const options = {
        method: "POST",
        headers: headers,
    };
    return fetch(backendConfig.backendEndpoint + 'getAllStrategicInitiative', options)
        .then(response => response.json())
        .catch(error => console.log(error))
}
export async function getAllBrandsData(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    headers.append("Content-Type", 'application/json');
    const options = {
        method: "POST",
        headers: headers,
    };
    return fetch(backendConfig.backendEndpoint + 'getAllBrands', options)
        .then(response => response.json())
        .catch(error => console.log(error))
}
export async function getAllHubsData(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    headers.append("Content-Type", 'application/json');
    const options = {
        method: "POST",
        headers: headers,
    };
    return fetch(backendConfig.backendEndpoint + 'getAllHubs', options)
        .then(response => response.json())
        .catch(error => console.log(error))
}
export async function getAllIndicationsData(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    headers.append("Content-Type", 'application/json');
    const options = {
        method: "POST",
        headers: headers,
    };
    return fetch(backendConfig.backendEndpoint + 'getAllIndications', options)
        .then(response => response.json())
        .catch(error => console.log(error))
}
export async function getAllTacticTypesData(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    headers.append("Content-Type", 'application/json');
    const options = {
        method: "POST",
        headers: headers,
    };
    return fetch(backendConfig.backendEndpoint + 'getAllTacticTypes', options)
        .then(response => response.json())
        .catch(error => console.log(error))
}
export async function getAllPrimaryCategoryData(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    headers.append("Content-Type", 'application/json');
    const options = {
        method: "POST",
        headers: headers,
    };
    return fetch(backendConfig.backendEndpoint + 'getAllCategory', options)
        .then(response => response.json())
        .catch(error => console.log(error))
}
export async function getAllPrimarySubCategoryData(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    headers.append("Content-Type", 'application/json');
    const options = {
        method: "POST",
        headers: headers,
    };
    return fetch(backendConfig.backendEndpoint + 'getAllSubCategory', options)
        .then(response => response.json())
        .catch(error => console.log(error))
}
export async function getAllSecondaryCategoryData(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    headers.append("Content-Type", 'application/json');
    const options = {
        method: "POST",
        headers: headers,
    };
    return fetch(backendConfig.backendEndpoint + 'getAllCategory', options)
        .then(response => response.json())
        .catch(error => console.log(error))
}
export async function getAllSecondarySubCategoryData(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    headers.append("Content-Type", 'application/json');
    const options = {
        method: "POST",
        headers: headers,
    };
    return fetch(backendConfig.backendEndpoint + 'getAllSubCategory', options)
        .then(response => response.json())
        .catch(error => console.log(error))
}
export async function createRequest(accessToken, data) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    headers.append("Content-Type", 'application/json');
    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify([data])
    };
    return fetch(backendConfig.backendEndpoint + 'createRequest', options)
        .then(response => response.json())
        .catch(error => console.log(error))
}

export async function updateRequest(accessToken, data) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    headers.append("Content-Type", 'application/json');
    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify([data])
    };
    return fetch(backendConfig.backendEndpoint + 'updateRequest', options)
        .then(response => response.json())
        .catch(error => console.log(error))
}
export async function getVVPMDocumentsByFilters(accessToken,filter) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    headers.append("Content-Type", 'application/json');
    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify([filter])
    };
    return fetch(backendConfig.backendEndpoint + 'getVVPMDocumentsByFilters', options)
        .then(response => response.json())
        .catch(error => console.log(error))
}
export async function updateGoNogoRequest(accessToken, data) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    headers.append("Content-Type", 'application/json');
    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data)
    };
    return fetch(backendConfig.backendEndpoint + 'updateGoNogoRequest', options)
        .then(response => response.json())
        .catch(error => console.log(error))
}
export async function generateRequestExcel(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    headers.append("Content-Type", 'application/json');
    const options = {
        method: "POST",
        headers: headers,
        // body: JSON.stringify(data)
    };
    return fetch(backendConfig.backendEndpoint + 'generateRequestExcel', options)
        .then(response => response.json())
        .catch(error => console.log(error))
}

export async function queryPage(accessToken, data) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    headers.append("Content-Type", 'application/json');
    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ query: data })
    };
    return fetch(backendConfig.backendEndpoint + 'executeDBQuery', options)
        .then(response => response.json())
        .catch(error => console.log(error))
}
