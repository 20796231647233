import React, {useState} from "react";
import "../styles/genericModal.css";
import logo from '../logo.svg';

const Modal = ({ title, handleClose, handleCancel, nogoPopout, handleSubmitNoGo, exceptionMessage}) => {
   const [comments, setComments] = useState('');
   const handleChange = (event) => {
    setComments(event.target.value);
   }
    return (
        <>
        <div className="generic-modal" >
        {!nogoPopout &&(
            <div className="generic-modal-content" style={{Padding:'10px'}}>
                <>  
                <div className="generic-modal-header" onClick={() => {}}>
                    <img className="lilly-logo-style" src={logo} alt="logo" height={50} width={70} />
                    
                </div>
                <div><h6 className="no-button-styles">{title}</h6></div>
                <div className="button-div-space">
                    {/* create a curated body to search vvpm attachments */}
                    <button className="btn btn-outline-primary" style={{marginRight:'10px'}} onClick={() => handleCancel()}>Yes</button>
                    <button className="btn btn-outline-danger" onClick={() => handleClose()}>No</button>
                </div>
                    
                </>
                
                </div>
                )}
                {nogoPopout && (
                <div className="nogo-modal-content">
                
                 <>
                  <div style={{height:'20px'}}>
                  <img className="lilly-logo-style" src={logo} alt="logo" height={50} width={70} />
                  </div>
                <div className="comment-space">
                    
                  <div className="comment-space-child">Please provide your comments here:</div>
                  <textarea type="input" placeholder="Enter text here...." className="comment-textarea"
                   value={comments}
                  onChange={handleChange}
                  >
                  </textarea>
                </div>
                <div className="button-box">
                <button className="btn btn-outline-primary " disabled={!comments} onClick={() => handleSubmitNoGo(comments)}>Submit</button>
                <button className="btn btn-outline-primary" style={{marginLeft:'10px'}} onClick={() => handleClose(false)}>Cancel</button>
                </div>
                </>
                    
            </div>
            )
        } 
            </div>
            </>
    )
};

export default Modal;
