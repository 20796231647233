import React , {useState} from "react";

const Header = ({ columns, onSorting }) => {
    const [sortingField, setSortingField] = useState("");
    const [sortingOrder, setSortingOrder] = useState("asc");
    const onSortingChange = (value) => {
        const order = value === sortingField && sortingOrder === 'asc' ? "desc" : "asc"
        setSortingField(value);
        setSortingOrder(order);
        onSorting(value, order);
    }
    return (
        <thead className="table-head">
            <tr>
                {columns.map(({key, value, sortable}) => (
                    <th className="table-head-th"
                        onClick={() => sortable ? onSortingChange(value) : null}
                        key={value}>
                        {key}
                        {sortingField && sortingField === value && (
                            sortingOrder === 'asc' ? '↑':'↓'
                        )}
                    </th>
                ))}
            </tr>
        </thead>
    )
}

export default Header;