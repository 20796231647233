import React from "react";
import '../styles/form.css';
/**
 * Renders information about the user obtained from MS Graph 
 * @param props
 */
const AccessDenied = (props) => {
  return (
    <div className="text-center display-4 access-denied-page">
      You don’t have access to this application. If you need access, please contact your affiliate's admin user.
    </div>
  );
};

export default AccessDenied;