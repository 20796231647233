import React from "react";
import "../../styles/form.css"

const Input = ({ value, label, placeholder, required, disabled, onInputChange, className, errorMessage, inputType }) => (
    <div className="input">
        <div className="input-label">
            {label ?
                <label>
                    {label}
                </label> : null
            }
            <span className="mandatory">
                {required ? ' *' : ''}
            </span>
        </div>
        <input
            type={inputType}
            min="0"
            className={`${className} w-100`}
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            onChange={(e) => onInputChange(e.target.value)}
        />
        <div className="mandatory">{errorMessage}</div>
    </div>
);

export default Input;