import React, { useState, useEffect } from 'react';
import { callMsGraph, callMsGraphGroup } from '../constants/graph';
import { loginRequest } from '../constants/authConfig';
import { useMsal } from '@azure/msal-react';
import { getUserDetails } from '../constants/apiCalls';
import { ProfileData } from './ProfileData';
import { useNavigate } from 'react-router-dom';
/**
 * Renders information about the user obtained VIA MsGraph
 * @param props
 */
export const ProfileSection = (props) => {
    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState(null);
    const [groupData, setGroupData] = useState(null);
    const [userDetails, setUserDetails] = useState(null);
    let navigate = useNavigate();
    function RequestProfileData() {
        // Silently acquires an access token which is then attached to a request for MS Graph data
        instance
            .acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            })
            .then((response) => {
                // add id token to localstorage for postman
                localStorage.setItem('id_token', response.idToken);
                callMsGraph(response.accessToken)
                    .then((graphresponse) => {
                        RequestUserDetails();
                        setGraphData(graphresponse)
                    });
            });
    }

    function RequestUserDetails() {
        instance
            .acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            })
            .then((response) => {
                getUserDetails(response.idToken, response.idToken)
                    .then((userData) => {
                        if (userData.data != null) {
                            setUserDetails({ ...userData.data });
                            localStorage.setItem('user', JSON.stringify(userData.data));
                        } else {
                            navigate('/accessdenied')
                        }
                    });
            });
    }

    function RequestGroupData() {
        RequestProfileData()
        instance
            .acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            })
            .then((response) => {
                callMsGraphGroup(graphData.id, response.accessToken).then((response) => setGroupData(response))
            })
    }

    useEffect(() => {
        if (graphData === null) {
            RequestProfileData();
        }
    }, [])
    return (
        <div className="profile-section">
            {userDetails ? <ProfileData data={userDetails} /> : null}
        </div>
    );
};