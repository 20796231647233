import React, { useEffect, useRef } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../constants/authConfig";
import Button from "react-bootstrap/Button";
import { useState } from "react";

export const SignInButton = ({ isAuthenticated }) => {

    const ref = useRef(null);

    const [accepted, setAccepted] = useState(false);

    const { instance } = useMsal();

    const handleLogin = () => {
        instance.loginRedirect(loginRequest).catch((e) => {
            console.log(e);
        });
    };

    useEffect(() => {
        if (!accepted) {
            setTimeout(() => {
                if (ref.current instanceof Element) {
                    setAccepted(true);
                    ref.current.click();
                }
            }, 1000); //miliseconds
        }
    }, [accepted]);


    return (
        <Button ref={ref}
            onClick={() => handleLogin()}
            style={{ "display": "none" }}
        > Sign In
        </Button>
    );
};