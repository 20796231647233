import React, { useEffect, useState } from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import DataTable from "../components/Datatable/Datatable";
import { useMsal } from '@azure/msal-react';
import Button from 'react-bootstrap/Button';
import {
  getUserDetails,
  getAllFormRequests,
  getBrandManageAllFormRequests,
  getCMOAllFormRequets,
  downloadExcelReportData
} from '../constants/apiCalls';
import { loginRequest } from '../constants/authConfig';
import {
  roles,
  assetLanguageData,
  channelData,
  currencyTypeData
} from "../constants/enum";
import '../styles/dashboard.css'
import { Link, useNavigate } from "react-router-dom";
/**
* Renders information about the user obtained from MS Graph 
* @param props
*/
const Dashboard = (props) => {
  const [userRole, setUserRole] = useState('');
  let navigate = useNavigate();
  const [userId, setUserId] = useState('');
  const [countryId, setCountryId] = useState('1');
  const [listingData, setListingData] = useState([]);
  const [columns, setColumns] = useState([]);
  const { instance, accounts } = useMsal();
  const [spinner, setSpinner] = useState(false);
  const [isLoading,setIsLoading] = useState(true);
  const [a ,setA] = useState(false)

  function RequestUserDetails() {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        // add id token to localstorage for postman
        localStorage.setItem('id_token', response.idToken);
        setSpinner(true);
        getUserDetails(response.idToken, response.idToken)
          .then((data) => {
            if (data.data != null) {
              let userData = data.data;
              setUserRole(roles[userData.role - 1]);
              setCountryId(userData.country_id);
              setUserId(userData.user_id);
              setA(true)
              setSpinner(false);
            } else {
              navigate('/accessdenied')
            }
          }).catch(err => console.log(err));
      })
      .catch(err => console.log(err))
  }
  // first useeffect to get the user role
  useEffect(() => {
    if (localStorage.getItem('user')) {
      let userData = JSON.parse(localStorage.getItem('user'));
      setUserRole(roles[userData.role - 1])
      setUserId(userData.user_id);
      setCountryId(userData.country_id);
      setA(true)
    } else {
      RequestUserDetails();
    }
  }, [])
  // useeffect to set the columns and listing Data used for listing
  useEffect(() => {
    if (userRole === "Admin") {
      setColumns([
        { key: 'Request Number', value: 'request_number', type: 'link', sortable: true },
        { key: 'Status', value: 'workflow_name', sortable: true },
        { key: 'Name', value: 'user_name', sortable: true },
        { key: 'Planned Budget', value: 'budget', sortable: true },
        { key: 'Actual Budget', value: 'asset_actual_cost', sortable: true },
        { key: 'Indication', value: 'indication', sortable: true },
        { key: 'Country', value: 'country_name', sortable: true },
        { key: 'Channel/Tactic', value: 'channel', sortable: true },
        { key: 'Language', value: 'asset_language', sortable: true },
      ])
      getAllFormRequestsForm();
    }
    if (userRole == "Brand Manager") {
      setColumns([
        { key: 'Request Number', value: 'request_number', sortable: true },
        { key: 'Status', value: 'workflow_name', sortable: true },
        { key: 'Name', value: 'user_name', sortable: true },
        { key: 'Planned Budget', value: 'budget', sortable: true },
        { key: 'Actual Budget', value: 'asset_actual_cost', sortable: true },
        { key: 'Indication', value: 'indication', sortable: true },
        { key: 'Country', value: 'country_name', sortable: true },
        { key: 'Channel/Tactic', value: 'channel', sortable: true },
        { key: 'Language', value: 'asset_language', sortable: true },
        { key: 'Action', value: 'action', type: 'icon' }
      ])
      getBrandManageAllFormRequestsForm();
    }
    if (userRole === "CMO") {
      setColumns([
        { key: 'Request Number', value: 'request_number', type: 'link', sortable: true },
        { key: 'Status', value: 'workflow_name', sortable: true },
        { key: 'Name', value: 'user_name', sortable: true },
        { key: 'Planned Budget', value: 'budget', sortable: true },
        { key: 'Actual Budget', value: 'asset_actual_cost', sortable: true },
        { key: 'Indication', value: 'indication', sortable: true },
        { key: 'Country', value: 'country_name', sortable: true },
        { key: 'Channel/Tactic', value: 'channel', sortable: true },
        { key: 'Language', value: 'asset_language', sortable: true },
      ])
      getCMOAllFormRequetsForm();
    }
  }, [userRole]);

  // functions for populating data
  function getAllFormRequestsForm() {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        setSpinner(true);
        getAllFormRequests(response.idToken)
          .then(data => {
            if (data.statusCode == 200) {
              setSpinner(false);
              setIsLoading(false);
              setListingData(data.data[0].map(x => ({
                ...x,
                indication: x.indication?.map(function (data) {
                  return data.indication_name
                }).join(","),
                asset_actual_cost:x.asset_actual_cost ? currencyTypeData[x.asset_actual_cost_currency - 1]?currencyTypeData[x.asset_actual_cost_currency - 1].label+" "+x.asset_actual_cost:''+" "+x.asset_actual_cost : '',
                budget:x.budget ? currencyTypeData[x.budget_currency - 1]?currencyTypeData[x.budget_currency - 1].label+" "+x.budget:''+" "+x.budget : '',
                asset_language: x.asset_language ? assetLanguageData[x.asset_language - 1].label : '',
                channel: x.channel?.map(function (data) {
                  return channelData[data - 1].label
                }).join(",")
              })))
            }
            else {
              setSpinner(true);
            }
          })
          .catch(err => console.log(err))
      })
      .catch(err => console.log(err))
  }
  function getBrandManageAllFormRequestsForm() {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        if(userId){
          getBrandManageAllFormRequests(response.idToken, { user_id: userId, country_id: countryId })
            .then(data => {
              if (data.statusCode == 200) {
                setSpinner(false);
                setIsLoading(false);
                setListingData(data.data[0].map(x => ({
                  ...x,
                  indication: x.indication?.map(function (data) {
                    return data.indication_name
                  }).join(","),
                  asset_actual_cost:x.asset_actual_cost ? currencyTypeData[x.asset_actual_cost_currency - 1]?currencyTypeData[x.asset_actual_cost_currency - 1].label+" "+x.asset_actual_cost:''+" "+x.asset_actual_cost : '',
                  budget:x.budget ? currencyTypeData[x.budget_currency - 1]?currencyTypeData[x.budget_currency - 1].label+" "+x.budget:''+" "+x.budget : '',
                  asset_language: x.asset_language ? assetLanguageData[x.asset_language - 1].label : '',
                  channel: x.channel?.map(function (data) {
                    return channelData[data - 1].label
                  }).join(",")
                })))
              }
              else {
                setSpinner(true);
              }
            })
            .catch(err => console.log(err))
        }
      })
      .catch(err => console.log(err))
  }
  function getCMOAllFormRequetsForm() {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        getCMOAllFormRequets(response.idToken, { country_id: countryId })
          .then(data => {
            if (data.statusCode == 200) {
              setSpinner(false);
              setIsLoading(false);
              setListingData(data.data[0].map(x => ({
                ...x,
                indication: x.indication?.map(function (data) {
                  return data.indication_name
                }).join(","),
                asset_actual_cost:x.asset_actual_cost ? currencyTypeData[x.asset_actual_cost_currency - 1]?currencyTypeData[x.asset_actual_cost_currency - 1].label+" "+x.asset_actual_cost:''+" "+x.asset_actual_cost : '',
                budget:x.budget ? currencyTypeData[x.budget_currency - 1]?currencyTypeData[x.budget_currency - 1].label+" "+x.budget:''+" "+x.budget : '',
                asset_language: x.asset_language ? assetLanguageData[x.asset_language - 1].label : '',
                channel: x.channel?.map(function (data) {
                  return channelData[data - 1].label
                }).join(",")
              })))
            }
            else {
              setSpinner(true);
            }
          })
          .catch(err => console.log(err))
      })
      .catch(err => console.log(err))
  }

  const downloadExcelReport = () => {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        downloadExcelReportData(response.idToken)
          .then(response => {
            if (response) {
              if (response.statusCode == "200") {
                let resultData = response.data;
                const linkSource = `data:${resultData.result.file_format};base64,${resultData.result.base64data}`;
                const downloadLink = document.createElement("a");
                const fileName = 'Forms List.xlsx';
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
              }
            }
          }).catch(err => console.log(err))
      })
  };
  return (
    <center>
      {a ? <div className="dashboard container">
        
          <>
            <Breadcrumbs pages={[{ label: "Home", path: "/" }]} />
            <div className="float-end me-2">
              {userRole == 'Brand Manager' ?
                <Link to={'form'}>
                  <Button variant="outline-primary" style={{marginRight: 10}}>Add New Form</Button>
                </Link> : null}
              {userRole == 'Admin' && (
              <Button variant="outline-primary" onClick={() => downloadExcelReport()}>Download Excel File</Button>
              )}
            </div>
            <DataTable
              data={listingData.length > 0 ? listingData : []}
              columns={columns}
              isLoading={isLoading}
              link={"request_id"}
              linkto={'/form'} />
          </>
        
      </div> : 
          <div className="spinner-border align-items-center justify-content-md-end">
          </div>}
        
    </center>
  );
};

export default Dashboard;