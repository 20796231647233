const roles = ["CMO","Brand Manager","Admin",];
const formSubmitterDetails = [
    {
        value: '7',
        label: 'Shreya Dhaga'
    },
    {
        value: '8',
        label: 'Tejaswini Tella'
    },
    {
        value: '9',
        label: 'Lokesh Gupta'
    },
    {
        value: '10',
        label: 'Nupur Shah'
    },
    {
        value: '11',
        label: 'Shubham Pathak'
    },
    {
        value: '12',
        label: 'Amit Pandey'
    },
];
const assetLanguageData = [
    {
        value: '1',
        label: 'French'
    },
    {
        value: '2',
        label: 'Korean'
    },
    {
        value: '3',
        label: 'English'
    },
];
const categoryData = [
    {
        value: '1',
        label: "Marketing"
    }
];
const approveBudgetData = [
    { value: 'true', label: 'Yes' },
    { value: 'false', label: 'No' }
];
const currencyTypeData = [
    { value: '1', label: 'EUR' },
    { value: '2', label: 'WON' },
    { value: '3', label: 'USD' },
];
const assetUsageData = [
    { value: '1', label: 'Local' },
    { value: '2', label: 'International' }
];
const customerSegmentaionData = [
    {
        value: '1',
        label: 'Customer Segmentation 1'
    },
    {
        value: '2',
        label: 'Customer Segmentation 2'
    },
    {
        value: '3',
        label: 'Customer Segmentation 3'
    },
];
const contentTypeData = [
    {
        value: '1',
        label: 'Priority Brand'
    },
    {
        value: '2',
        label: 'Non-Priority Brand'
    },
];
const channelData = [
    {
        value: '1',
        label: 'eP2P'
    },
    {
        value: '2',
        label: 'eP2P Repeated'
    },
    {
        value: '3',
        label: 'P2P'
    },
    {
        value: '4',
        label: 'Email'
    },
    {
        value: '5',
        label: 'SoA'
    },
    {
        value: '6',
        label: 'IVA'
    },
    {
        value: '7',
        label: 'VAE'
    },
    {
        value: '8',
        label: 'Social Media'
    },
    {
        value: '9',
        label: 'VOD'
    },
    {
        value: '10',
        label: 'Other'
    },
];
const assetTacticReuseData = [
    {
        value: '1',
        label: 'New Development'
    },
    {
        value: '2',
        label: 'Re-use'
    },
];
const assetObjectiveData = [
    {
        value: '1',
        label: 'Option 1'
    },
    {
        value: '2',
        label: 'Option 2'
    },
];
const vvpmResults = [
    {
        document_id: '1',
        document_name:'Document',
        country:'India',
    },
    {
        document_id: '2',
        document_name:'Document',
        country:'India',
    },
];
export {
    roles,
    formSubmitterDetails,
    assetLanguageData,
    approveBudgetData,
    currencyTypeData,
    assetUsageData,
    customerSegmentaionData,
    contentTypeData,
    channelData,
    assetTacticReuseData,
    assetObjectiveData,
    categoryData,
    vvpmResults
}