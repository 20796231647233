import Header from "./Header";
import Pagination from "./TablePagination"
import eye from "../../icons/eye.svg";
import { useMemo, useState, useEffect } from "react";
import { Link } from 'react-router-dom';

const DataTable = ({ data, columns, linkto, link ,isLoading }) => {
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState('');
    const [sorting, setSorting] = useState({ value: "", order: "" });
    const [location, setLocation] = useState('');
    const [spinner, setSpinner] = useState(false);

    useEffect(() => {
        setLocation(window.location.origin);
    }, [])
    const ITEMS_PER_PAGE = 5;
    const listingData = useMemo(() => {
        let listedData = data;
        if (search) {
            listedData = listedData.filter(list =>
                list.indication.toLowerCase().includes(search.toLowerCase()) ||
                list.channel?.toLowerCase().includes(search.toLowerCase()) ||
                list.asset_language?.toLowerCase().includes(search.toLowerCase())
            )
        }
        setTotalItems(listedData.length);

        // Sorting data
        if (sorting.value !== "budget") {
            const reversed = sorting.order === 'asc' ? 1 : -1;
            listedData = listedData.sort((a, b) => reversed * a[sorting.value]?.localeCompare(b[sorting.value]))
        }
        else if (sorting.value == "budget") {
            const reversed = sorting.order === 'asc' ? 1 : -1;
            listedData = listedData.sort((a, b) => reversed * a[sorting.value] - (b[sorting.value]))
        }
        // current Page slice
        return listedData.slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE)
    }, [data, currentPage, search, sorting])
    if(listingData.length < 0) {
        setSpinner(true)
    }
    return (
        
            <div className="row w-100">
                <div className="col mb-3 col-12 text-center">
                    <table className="table table-striped">
                        <Header columns={columns} onSorting={(value, order) => setSorting({ value, order })} />
                        <tbody>
                            {listingData.length > 0 ?
                               listingData.map(row =>
                                    <tr>
                                        {columns.map(col => {
                                            if (col.type == 'link') {
                                                return (<td>
                                                    <Link key={`${location}${linkto}/${row[link]}`} to={`${location}${linkto}/${row[link]}`}
                                                        style={{ textDecoration: 'underline' }}>
                                                        {row[col.value]}
                                                    </Link>
                                                </td>)
                                            } else if (col.type == 'icon') {
                                                return (<td>
                                                    <Link key={`${location}${linkto}/${row[link]}`} to={`${linkto}/${row[link]}`}
                                                        style={{ textDecoration: 'none' }}>
                                                        <img src={eye} />
                                                    </Link>
                                                </td>)
                                            }
                                            else {
                                                return (<td> { row[col.value] }</td>) 
                                            }
                                        })}
                                    </tr>
                                ) :
                                <>{isLoading ? <tr>
                                    <td colSpan={Object.keys(columns).length}>
                                    {<div className="spinner-border align-items-center justify-content-md-end"></div>}
                                    </td>
                                </tr>: <tr>
                                    <td colSpan={Object.keys(columns).length}>
                                     {"Sorry!! you are not assigned with any requests"}
                                    </td>
                                </tr>}</>
                                }
                        </tbody>
                    </table>
                    <div className="row">
                        <div className="col-md-6">
                            <Pagination
                                totalItems={totalItems}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                                itemsPerPage={ITEMS_PER_PAGE}
                                siblingCount={1} />
                        </div>
                    </div>
                </div>
            </div>
      
    )
}
export default DataTable;