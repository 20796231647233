import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';


function Breadcrumbs(props) {
    return (
        <Breadcrumb>
            {props.pages && props.pages.length > 0 && props.pages.map(
                (item) => {
                    if (window.location.pathname
                        .startsWith(`${item['path']}`)) {
                        return (
                            <Breadcrumb.Item>
                                <Link key={item} to={item['path']} style={{ textDecoration: 'none' }}>
                                {item['label']}
                                </Link>
                            </Breadcrumb.Item>
                        )
                    } else {
                        return (
                            <Breadcrumb.Item>
                                <Link key={item} to={item['path']} style={{ textDecoration: 'none' }}>
                                {item['label']}
                                </Link>
                            </Breadcrumb.Item>
                        )
                    }
                })
            }
        </Breadcrumb>
    )
}

export default Breadcrumbs;