import React from 'react';
import { PageLayout } from './components/PageLayout';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import IntakeForm from './pages/IntakeForm';
import AccessDenied from './pages/AccessDenied';
import QueryPage from './pages/QueryPage';
/**
* If a user is authenticated then switch tabs. Otherwise a message indicating a user is not authenticated is rendered.
*/
const MainContent = () => {
    return (
        <div className="App">
            <AuthenticatedTemplate>
                <Routes>
                    <Route exact path='/' element={
                        <Dashboard />
                    } />
                    <Route exact path='/form' element={
                        <IntakeForm />
                    } />
                    <Route exact path='/form/:id' element={
                        <IntakeForm />
                    } />
                    <Route exact path='/accessdenied' element={
                        <AccessDenied />
                    } />
                    <Route exact path='/querypage' element={
                        <QueryPage />
                    } />
                </Routes>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <h5>
                    <center>
                        Signing In
                    </center>
                </h5>
            </UnauthenticatedTemplate>
        </div>
    );
};

export default function App() {
    return (
        <BrowserRouter>
            <PageLayout>
                <MainContent />
            </PageLayout>
        </BrowserRouter>
    );
}
