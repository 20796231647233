import React, { useState } from "react";
import Input from '../components/Form/Input';
import { useMsal } from '@azure/msal-react';
import { queryPage } from '../constants/apiCalls';
import { loginRequest } from '../constants/authConfig';
import Button from 'react-bootstrap/Button';
/**
 * Renders information about the user obtained from MS Graph 
 * @param props
 */
const QueryPage = (props) => {
    const { instance, accounts } = useMsal();
    const [query, setQuery] = useState('');
    const [queryResponse, setQueryResponse] = useState('');
    const handleSubmit = () => {
        instance
            .acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            })
            .then(async (response) => {
                await queryPage(response.idToken, query)
                    .then(res => {
                        setQueryResponse(res.message);
                    }).catch(err => {
                        setQueryResponse(err)
                    })
            })
            .catch(err => {
                setQueryResponse(err)
            })
    }

    return (
        <div>
            <div className="text-center display-4">
                QueryPage
            </div>
            <Input
                inputType="text"
                value={query}
                onInputChange={(value) => setQuery(value)}
            />
            <Button onClick={handleSubmit}>Submit</Button>
            {queryResponse ?
                <div>
                    {JSON.stringify(queryResponse)}
                </div> : null}
        </div>
    );
};

export default QueryPage;