import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../styles/form.css"
import moment from "moment";

const FormDatePicker =
    ({ label, required, selectedDate, onDateChange,
        className, disabled, placeholder, errorMessage }) => (
        <div className='datepicker'>
            <div className='datepicker-label'>
                {label ?
                    <label>
                        {label}
                    </label> : null
                }
                <span className="mandatory">{required ? ' *' : ''}</span>
            </div>
            <DatePicker
                dateFormat="QQQ yyyy/dd"
                selected={selectedDate}
                onChange={onDateChange}
                minDate={moment().toDate()}
                className={`${className} w-100`}
                disabled={disabled}
                placeholderText={placeholder ? placeholder : "Select Date"}
            />
            <div className="mandatory">{errorMessage}</div>
        </div>
    )
export default FormDatePicker;
