import React, { useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";

const PaginationComponent = ({ totalItems = 0, currentPage, onPageChange, itemsPerPage, siblingCount }) => {
    const [totalPages, setTotalPages] = useState(0);
    const [paginationItems, setPaginationItems] = useState([]);
    useEffect(() => {
        if (totalItems > 0 && itemsPerPage > 0) {
            setTotalPages(Math.ceil(totalItems / itemsPerPage))
        }
    }, [totalItems, itemsPerPage]);
    useEffect(() => {
        const paginationItems = [];
        if (currentPage == 1) {
            paginationItems.slice(createPaginationItem(1));
            paginationItems.push(createPaginationItem(currentPage));
        }
        // Add an ellipsis
        if (currentPage > 3)
            paginationItems.push(<Pagination.Ellipsis onClick={() => onPageChange(Math.ceil(currentPage / 2))} />);
        // Create page numbers in the middle
        if (currentPage - 1 > 1 && currentPage - 1 < totalPages)
            paginationItems.push(createPaginationItem(currentPage - 1));
        if (currentPage > 1 && currentPage < totalPages)
            paginationItems.push(createPaginationItem(currentPage));
        // if (currentPage + 1 > 1 && currentPage + 1 < totalPages)
        //     paginationItems.push(createPaginationItem(currentPage + 1));
        if (currentPage + 3 < totalPages)
            paginationItems.push(<Pagination.Ellipsis onClick={() => onPageChange(currentPage + Math.ceil((totalPages - currentPage) / 2))} />);
        paginationItems.push(createPaginationItem(totalPages));
        setPaginationItems(paginationItems)
    }, [currentPage, totalPages])
    const createPaginationItem = (i) => {
        return <Pagination.Item
            key={i}
            active={i === currentPage}
            onClick={() => onPageChange(i)}
        >
            {i}
        </Pagination.Item>
    };

    if (totalPages === 0) return null;
    return (
        <Pagination>
            <Pagination.First onClick={() => onPageChange(1)} disabled={currentPage === 1} />
            <Pagination.Prev onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1} />
            {paginationItems}
            <Pagination.Next onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages} />
            <Pagination.Last onClick={() => onPageChange(totalPages)} disabled={currentPage === totalPages} />
        </Pagination>
    )
}

export default PaginationComponent;