import React, { useState } from "react";
import user from "../icons/user.svg";
import { roles } from '../constants/enum'
/**
 * Renders information about the user obtained from MS Graph 
 * @param props
 */
export const ProfileData = ({ data }) => {
    const [showDetails, setShowDetails] = useState(false);
    const handleToggle = () => {
        setShowDetails(!showDetails)

    }
    return (
        <div className="profile dropdown">
            <div className="user-name" data-bs-toggle="dropdown" onClick={() => handleToggle()}>
                <img src={user} alt='user' height={'40px'} width={'40px'} />
            </div>
            <div className={showDetails ? "dropdown-menu show" : "dropdown-menu"} id="login_dropdown" aria-labelledby="dropdownMenuLink">
                <span className="dropdown-item">Name: {data.user_name} </span>
                <span className="dropdown-item">Role: {roles[data.role - 1]} </span>
                {roles[data.role - 1] != 'Admin' && (
                    <span className="dropdown-item">Country: {data.country_name} </span>
                )}
            </div>
        </div>
    );
};