import React from 'react';
import Select from "react-select";
import "../../styles/form.css"

const FormSelect = ({ value, options, label, placeholder, required, disabled, closeMenuOnSelect, onChange, className, ismulti, errorMessage }) => (
    <div className='select'>
        <div className='select-label'>
        {label ?
                <label>
                    {label}
                </label> : null
            }
            <span className='mandatory'>{required ? ' *' : null} </span>
        </div>
        <Select
            onChange={onChange}
            required={required}
            isDisabled={disabled}
            options={options}
            value={value}
            hideSelectedOptions={false}
            closeMenuOnSelect={closeMenuOnSelect}
            isMulti={ismulti}
            className={className}
            placeholder={placeholder ? placeholder : `Select ${label}`}
            />
        <div className="mandatory">{errorMessage}</div>
    </div>
);

export default FormSelect;